declare var FS: any;

import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter, map, tap } from 'rxjs/operators';

import { AuthService } from './services/auth.service';
import { HelperAuthService } from './services/helper-auth.service';
import { UserService } from './services/user.service';

// IMPORTANT: both development / production databases persist Lenita's "Home" ID as a 1 value; if using a local database
// your home organization will need to be set to this value for features to work properly

const LENITA_HOME_ORGANIZATION_ID = 1;   // value for organization reset behaviors; defaults to Lenita's test organization


@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	public constructor(
		private readonly _activatedRoute: ActivatedRoute,
		private readonly _authService: AuthService,
		private readonly _authServiceHelper: HelperAuthService,
		private readonly _router: Router,
		private readonly _title: Title,
		private readonly _userService: UserService
	) { }

	public isDangerZone = false;

	public ngOnInit(): void {
		this._router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),

				tap(async () => {
					let user;

					try {
						user = (await this._userService.getRefUser())?.data;

						if (!user) {
							this._clearFSUser();

							return;
						}
						else {
							// determine if user is navigating while belonging to a third-party organization - if so, let's let them know very prominently
							const userOrgFromDatabase                = user.organizationId;
							const userOrgFromApplicationLocalStorage = this._authService.getUser().organizationId;

							if (userOrgFromDatabase !== userOrgFromApplicationLocalStorage)
								console.warn(`User IDs are different: userOrgFromDatabase: ${userOrgFromDatabase} / userOrgFromApplicationLocalStorage ${userOrgFromApplicationLocalStorage}`);

							this.isDangerZone = user.staffFlag && userOrgFromApplicationLocalStorage > LENITA_HOME_ORGANIZATION_ID;
						}
					}
					catch {		// we won't end up here since HTTP request errors get swallowed (see REST provided service in app)
						this._clearFSUser();

						return;
					}

					// if nothing short-circuited this "tap" above, configure Fullstory integration with user
					this._setFSUser(user);
				}),

				map(() => this._activatedRoute),

				map(route => {
					while (route.firstChild)
						route = route.firstChild;

					return route;
				})
			)
			.subscribe(route => {
				const routeData = route.snapshot.data;
				this._title.setTitle(routeData.title ? routeData.title + ' | ProcedureCard' : 'ProcedureCard');

				window.scrollTo(0, 0);
			});
	}

	public resetOrganization(): void {
		this._authServiceHelper.setOrganizationAuth(LENITA_HOME_ORGANIZATION_ID)
			.then(() => this.isDangerZone = false)
			.catch(() => this.isDangerZone = true)
			.finally(() => {
				this._authService.logout();
				this._router.navigate(["login"]);
			});
	}

	private _isUserReportedToFS = false;

	private _clearFSUser() {
		try {
			FS.identify(null);
			this._isUserReportedToFS = false;
		}
		catch (error) {
			console.error(error);
		}
	}

	private _setFSUser(user: any) {
		if (this._isUserReportedToFS)
			return;

		try {
			FS.identify(user.username, user);
			this._isUserReportedToFS = true;
		}
		catch (error) {
			console.error(error);
		}
	}
}
