import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from './../../environments/environment';

import { ProcedureCopyStatusType, ProcedureEntity } from './../common/types/procedure';

import { AuthService } from './auth.service';
import { RestService } from './rest.service';


@Injectable({
   providedIn: 'root',
})
export class ProcedureService {
   constructor(
      private readonly _auth: AuthService,
      private readonly _http: HttpClient,
      private readonly _rest: RestService
   ) { }

   getProcedures(nameFilter: string, providerFilter: number): Promise<any> {
      return this._rest.get(`${environment.apiUrl}/api/procedure?nameFilter=${nameFilter || ""}&providerFilter=${providerFilter || 0}`);
   }

   getProcedureCount(): Promise<number> {
      return this._http.get<number>(`${environment.apiUrl}/api/procedure/number-of-procedures?organizationId=${this._auth.user.organizationId}`).toPromise();
   }

   getProcedureTemplates(): Promise<any> {
      return this._rest.get(`${environment.apiUrl}/api/procedure?type=template`);
   }

   getProcedure(id: number): Promise<any> {
      return this._rest.get(`${environment.apiUrl}/api/procedure/${id}`);
   }

   getProcedureTemplate(id: number): Promise<any> {
      return this._rest.get(`${environment.apiUrl}/api/procedure/${id}?type=template`);
   }

   createProcedure(payload): Promise<any> {
      return this._rest.post(`${environment.apiUrl}/api/procedure`, payload);
   }

   createProcedureFromTemplate(payload): Promise<any> {
      return this._rest.post(`${environment.apiUrl}/api/procedure?ref=template`, payload);
   }

   saveProcedure(id: number, payload): Promise<any> {
      return this._rest.put(`${environment.apiUrl}/api/procedure/${id}`, payload);
   }

   logRevisions(id: number, payload): Promise<any> {
      return this._rest.post(`${environment.apiUrl}/api/procedure/${id}/revision-log`, payload);
   }

   deleteProcedure(id: number): Promise<any> {
      return this._rest.delete(`${environment.apiUrl}/api/procedure/${id}`);
   }

   downloadPdf(id: number): Promise<any> {
      return this._rest.post(`${environment.apiUrl}/api/procedure-pdf/${id}`, {});
   }

   downloadProcedureCopyPdf(procedureCopyId: number): Promise<any> {
      return this._rest.post(`${environment.apiUrl}/api/procedure-pdf/procedure-copy/${procedureCopyId}`, {});
   }

   getAutocompleteItems(procedureEntity: ProcedureEntity): Promise<any> {
      return this._rest.get(`${environment.apiUrl}/api/procedure/autocomplete?type=${procedureEntity}`);
   }

   createProcedureCopy(procedureId: string): Promise<any> {
      return this._rest.post(`${environment.apiUrl}/api/procedure/create-procedure-copy/${procedureId}`, {});
   }

   getAllProcedureCopies(nameFilter: string, providerFilter: number, statusFilter: string, dateFilter: number, startDate: string, endDate: string): Promise<any> {
      return this._rest.get(`${environment.apiUrl}/api/procedure/get-procedure-copies?nameFilter=${nameFilter || ""}&providerFilter=${providerFilter || 0}&statusFilter=${statusFilter}&dateFilter=${dateFilter}&startDate=${startDate}&endDate=${endDate}`);
   }

   getProcedureCopy(id: number): Promise<any> {
      return this._rest.get(`${environment.apiUrl}/api/procedure/get-procedure-copies/${id}`);
   }

   updateProcedureCopy(procedureCopyId: number, payload: any): Promise<any> {
      return this._rest.put(`${environment.apiUrl}/api/procedure/update-procedure-copy/${procedureCopyId}`, payload);
   }
}
